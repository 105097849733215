import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/opt/build/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/opt/build/repo/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0__32vue_toastifications_3zpJDWPCq1 from "/opt/build/repo/plugins/0. vue-toastifications.ts";
import _1__32store_code_client_TJ6XH8hgOl from "/opt/build/repo/plugins/1. store-code.client.ts";
import _2__32i18n_ESwmpkVmCD from "/opt/build/repo/plugins/2. i18n.ts";
import _3__32customer_token_client_o4uNBdlFth from "/opt/build/repo/plugins/3. customer-token.client.ts";
import _4__32get_cart_client_reU4nxVuC8 from "/opt/build/repo/plugins/4. get-cart.client.ts";
import _5__32validations_uARTZECQf6 from "/opt/build/repo/plugins/5. validations.ts";
import address_helper_vgVHfuXVas from "/opt/build/repo/plugins/address-helper.ts";
import error_handler_kEP5FliEXj from "/opt/build/repo/plugins/error-handler.ts";
import format_price_3WLkrmGvIB from "/opt/build/repo/plugins/format-price.ts";
import swatch_helper_mh5FwkNw7I from "/opt/build/repo/plugins/swatch-helper.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  _0__32vue_toastifications_3zpJDWPCq1,
  _1__32store_code_client_TJ6XH8hgOl,
  _2__32i18n_ESwmpkVmCD,
  _3__32customer_token_client_o4uNBdlFth,
  _4__32get_cart_client_reU4nxVuC8,
  _5__32validations_uARTZECQf6,
  address_helper_vgVHfuXVas,
  error_handler_kEP5FliEXj,
  format_price_3WLkrmGvIB,
  swatch_helper_mh5FwkNw7I
]