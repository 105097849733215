import { createI18n } from 'vue-i18n'
import en from '../locales/en.json'
import de from '../locales/de.json'
import nl from '../locales/nl.json'
import fr from '../locales/fr.json'
import es from '../locales/es.json'

export default defineNuxtPlugin(({ vueApp }) => {
    const localeCode = useLocaleCode();

    const i18n = createI18n({
        legacy: false,
        globalInjection: true,
        locale: localeCode.value ?? 'en',
        fallbackLocale: 'en',
        messages: {
            en,
            de,
            nl,
            fr,
            es,
        }
    });

    vueApp.use(i18n);
})
